import { isContentFragment } from '@oneaudi/falcon-tools';
import { Content, HeadlessContent, LinkWithCurrentPage } from '../ContentType';

const defaultLinkText = 'Link text placeholder';
const defaultHref = 'https://www.audi.de';

export const mapLinks = (content: HeadlessContent): LinkWithCurrentPage[] =>
  content.fields.links?.map((link) => ({
    href: link.fields.href || defaultHref,
    text: link.fields.text || defaultLinkText,
    opensInNewTab: link.fields.opensInNewTab === true,
    currentPage: link.fields.currentPage === true,
  })) || [
    {
      text: defaultLinkText,
      href: defaultHref,
      opensInNewTab: false,
      currentPage: false,
    },
  ];

function validateLinks(links: unknown): boolean {
  return Array.isArray(links) && links.every((link) => isContentFragment(link));
}

export const mapHeadlessContent = (content: Content | HeadlessContent): Content => {
  if ('__type' in content && content.__type === 'aem-headless') {
    const links = validateLinks(content.fields.links)
      ? mapLinks(content)
      : [
          {
            text: defaultLinkText,
            href: defaultHref,
            opensInNewTab: false,
            currentPage: false,
          },
        ];

    const cta =
      content.fields.cta_0_text &&
      content.fields.cta_0_text.length > 0 &&
      content.fields.cta_0_href &&
      content.fields.cta_0_href.length > 0
        ? [
            {
              text: content.fields.cta_0_text,
              href: content.fields.cta_0_href,
              opensInNewTab: content.fields.cta_0_opensInNewTab,
            },
          ]
        : [];

    return {
      headline: content.fields.headline || 'Headline Placeholder',
      href: content.fields.href || '',
      links,
      cta,
    };
  }
  return content as Content;
};
